import { Grid, Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import FormField from './FormField';
import StateSelect from './StateSelect';

const AddressFields = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormField label="Address Line 1" error={errors.addressLine1}>
          <Controller
            control={control}
            name="addressLine1"
            render={({ field }) => (
              <TextField
                id="addressLine1"
                placeholder="Enter address line 1"
                variant="outlined"
                fullWidth
                {...field}
                error={!!errors.addressLine1}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid item xs={12}>
        <FormField label="Address Line 2" error={errors.addressLine2}>
          <Controller
            control={control}
            name="addressLine2"
            render={({ field }) => (
              <TextField
                id="addressLine2"
                placeholder="Enter address line 2"
                variant="outlined"
                fullWidth
                {...field}
                error={!!errors.addressLine2}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid item xs={12}>
        <FormField label="City" error={errors.city}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextField
                id="city"
                placeholder="Enter city"
                variant="outlined"
                fullWidth
                {...field}
                error={!!errors.city}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <StateSelect name="state" label="State" />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <FormField label="Zip Code" error={errors.zip}>
          <Controller
            control={control}
            name="zip"
            render={({ field }) => (
              <TextField
                id="zip"
                placeholder="Enter zip code"
                variant="outlined"
                fullWidth
                {...field}
                error={!!errors.zip}
                size="small"
              />
            )}
          />
        </FormField>
      </Grid>
    </Grid>
  );
};

export default AddressFields;
