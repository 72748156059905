'use client';

import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'context/AppProvider';
import { Container } from '@mui/material';
import { ZENDESK_CONVERSATION_FIELDS } from 'shared/lib/constants';
import { zdCustomFields } from './zendesk';
import { usePathname } from 'navigation';
import { getCookie } from 'cookies-next';
import { COOKIE_ONBOARDING } from 'shared/lib/constants';

export const ZendeskFieldValues = () => {
  const { patientFieldId, healthieFieldId, screenFieldId, urlFieldId } =
    ZENDESK_CONVERSATION_FIELDS;
  const pathname = usePathname();
  const cookie = getCookie(COOKIE_ONBOARDING);
  const [patientIdValue, setPatientIdValue] = useState<string | null>();
  const [healthieIdValue, setHealthieIdValue] = useState<string | null>();
  const [screenIdValue, setScreenIdValue] = useState<string | null>();
  const [pathnameValue, setPathnameValue] = useState<string | null>();

  const screenId = cookie ? JSON.parse(cookie).screenId : '';

  const { user } = useContext(AppContext);
  const { id: patientId, healthieId } = user?.patient || {};

  // necessary hoop to avoid errors with SSR hydration mismatch
  useEffect(() => {
    setPatientIdValue(patientId);
    setHealthieIdValue(healthieId);
    setScreenIdValue(screenId);
    setPathnameValue(pathname);
    zdCustomFields();
  }, [patientId, healthieId, screenId, pathname]);

  return (
    <Container sx={{ display: 'none' }}>
      <label id={`${patientFieldId}`} data-testid={`${patientFieldId}`}>
        {patientIdValue}
      </label>
      <label id={`${healthieFieldId}`} data-testid={`${healthieFieldId}`}>
        {healthieIdValue}
      </label>
      <label id={`${screenFieldId}`} data-testid={`${screenFieldId}`}>
        {screenIdValue}
      </label>
      <label id={`${urlFieldId}`} data-testid={`${urlFieldId}`}>
        {pathnameValue}
      </label>
    </Container>
  );
};
