'use client';

import { sendGTMEvent, GoogleTagManager } from '@next/third-parties/google';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { AppContext } from './AppProvider';
import { usePathname } from 'navigation';

type Props = {
  children: ReactNode;
};

const AnalyticsProvider = ({ children }: Props) => {
  const pathname = usePathname();
  const { utmParams } = useContext(AppContext);

  // Solution: https://github.com/vercel/next.js/issues/4662#issuecomment-908684049
  // another solution: https://github.com/vercel/next.js/issues/11639#issuecomment-1219654377
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);

  if (
    typeof window !== 'undefined' &&
    !initialRouteTracked &&
    window.location.search === '' &&
    window.performance.getEntriesByType &&
    window.performance.getEntriesByType('navigation').length > 0 &&
    (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type !==
      'reload'
  ) {
    setInitialRouteTracked(true);
    sendGTMEvent({
      event: 'gtm.js',
      page: pathname,
      utm: utmParams,
    });
  }

  useEffect(() => {
    sendGTMEvent({
      event: 'gtm.js',
      page: pathname,
      utm: utmParams,
    });
  }, [pathname, utmParams]);

  return (
    <>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID as string} />
      {children}
    </>
  );
};

export default AnalyticsProvider;
