'use client';

import { datadogLogs } from '@datadog/browser-logs';
import useLocalStorage from 'hooks/useLocalStorage';
import { usePathname, useRouter } from 'navigation';
import { FC, ReactNode, createContext, useContext, useEffect } from 'react';

const scheduleFlow = ['/onboarding/adhd/schedule', '/onboarding/appointment', '/'];

export const onboarding_adhd_diagnosis_asd = '/onboarding/adhd/diagnosis-asd';
export const onboarding_adhd_diagnosis_asd_upload = '/onboarding/adhd/diagnosis-asd-upload';

const flowMap: Record<string, string[]> = {
  add_child: [
    '/family',
    '/onboarding/adhd/add-child',
    '/onboarding/adhd/diagnosis-adhd',
    '/onboarding/adhd/provider',
    '/onboarding/adhd',
  ],
  registration: [
    '/adhd',
    '/adhd/child-name',
    '/adhd/child-age',
    '/adhd/state',
    '/adhd/referral-source',
    '/adhd/eligible-success',
    '/auth/signup',
    '/auth/verify-request',
  ],
  pc: [
    '/onboarding/adhd',
    '/onboarding/adhd/diagnosis-adhd-upload',
    onboarding_adhd_diagnosis_asd,
    onboarding_adhd_diagnosis_asd_upload,
    '/onboarding/insurance/options',
  ],
  pc_forms: ['/forms', '/dashboard'],
  forms: ['/forms', '/dashboard'],
  intake: [
    '/onboarding/adhd',
    '/onboarding/adhd/parent-vanderbilt',
    '/onboarding/adhd/parent-vanderbilt/form',
    '/onboarding/adhd/teacher-vanderbilt',
    '/onboarding/adhd/teacher-vanderbilt/form',
    '/forms',
    '/onboarding/insurance/options',
  ],
  referral_dx: ['/onboarding/adhd', '/forms', '/onboarding/insurance/options'],
  self_referral: [
    '/onboarding/adhd/add-child',
    '/onboarding/adhd/behaviors',
    '/onboarding/referral',
    '/onboarding/adhd/diagnosis-adhd',
    '/onboarding/adhd/provider',
    '/onboarding/adhd',
  ],
  onboarding_insurance: [
    '/onboarding/insurance/primary',
    '/onboarding/payment',
    '/onboarding/address',
    ...scheduleFlow,
  ],
  onboarding_self_pay: ['/onboarding/payment', '/onboarding/address', ...scheduleFlow],
  onboarding_schedule: scheduleFlow,
};

type ContextProps = {
  setFlow: any;
  addFlow: any;
  removeFlow: any;
  addNextFlowPage: any;
  removeNextFlowPage: any;
  clearFlow: any;
  getIndex: any;
  getNumSteps: any;
  goToNextPage: any;
  goToPrevPage: any;
  goToHomePage: any;
  appendNextPage: any;
};

const NavigationContext = createContext<ContextProps>({
  setFlow: () => {},
  addFlow: () => {},
  removeFlow: () => {},
  addNextFlowPage: () => {},
  removeNextFlowPage: () => {},
  clearFlow: () => {},
  getIndex: () => 0,
  getNumSteps: () => 0,
  goToNextPage: () => {},
  goToPrevPage: () => {},
  goToHomePage: () => {},
  appendNextPage: () => {},
});

type Props = {
  children: ReactNode;
};

const NavigationProvider: FC<Props> = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const pathname = usePathname();
  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const [_, setCurrentState, clearState, getCurrentState] = useLocalStorage<Record<string, any>>(
    'clarity:navstate',
    {},
  );

  const setFlow = (key: string) => {
    setCurrentState({
      keys: [key],
      flow: flowMap[key],
    });

    return flowMap[key];
  };

  const generateFlow = (keys: string[]) => {
    let flow: string[] = [];

    for (const k of keys) {
      if (flowMap[k]) {
        flow = flow.concat(flowMap[k] as string[]);
      }
    }
    setCurrentState({
      keys,
      flow,
    });
  };

  const addFlow = (key: string) => {
    const navstate = getCurrentState();

    if (!navstate?.keys) {
      setFlow(key);
      return;
    }

    const keys: string[] = navstate.keys.concat([key]);
    generateFlow(keys);
  };

  const removeFlow = (key: string) => {
    const navstate = getCurrentState();

    if (!navstate?.keys) {
      return;
    }

    const index = navstate.keys.indexOf(key);

    if (index === -1) {
      return;
    }

    navstate.keys.splice(index, 1);
    generateFlow(navstate.keys);
  };

  const addNextFlowPage = (current: string, next: string) => {
    const navstate = getCurrentState();
    if (!navstate?.keys) {
      return;
    }

    const index = navstate.flow.indexOf(current);
    if (index === -1) {
      return;
    }

    navstate.flow.splice(index, 0, next);
    setCurrentState(navstate);
  };

  const removeNextFlowPage = (next: string) => {
    const navstate = getCurrentState();
    if (!navstate?.keys) {
      return;
    }

    const index = navstate.flow.indexOf(next);
    if (index === -1) {
      return;
    }

    navstate.flow.splice(index, 1);
    setCurrentState(navstate);
  };

  const appendNextPage = (next: string) => {
    const navstate = getCurrentState();
    if (!navstate?.keys) {
      return;
    }

    navstate.flow.push(next);
    setCurrentState(navstate);
  };

  const getIndex = () => {
    const navstate = getCurrentState();

    if (navstate?.flow) {
      const idx = navstate.flow.findIndex((path: string) => {
        return `${pathname}${window.location.search}`.endsWith(path);
      });

      return idx;
    }

    return -1;
  };

  const getNumSteps = () => {
    const navstate = getCurrentState();
    if (navstate?.flow) {
      return navstate.flow.length;
    }

    return 0;
  };

  const goToNextPage = (count?: number) => {
    const navstate = getCurrentState();

    if (navstate?.flow) {
      let idx = getIndex();
      idx += typeof count === 'number' ? count : 1;

      const nextPath = navstate.flow[idx];

      if (nextPath) {
        router.push(nextPath);
      } else {
        datadogLogs.logger.error('No next path found', {
          flow: navstate.flow,
          idx,
        });
        router.push('/');
      }
    }
  };

  const goToPrevPage = (count?: number) => {
    const navstate = getCurrentState();

    if (navstate?.flow) {
      let idx = getIndex();
      idx -= typeof count === 'number' ? count : 1;

      const prevPath = navstate.flow[idx];

      if (prevPath) {
        router.push(prevPath);
      } else {
        datadogLogs.logger.error('No prev path found', {
          flow: navstate.flow,
          idx,
        });
        router.push('/');
      }
    }
  };

  const goToHomePage = () => {
    router.push('/');
  };

  return (
    <NavigationContext.Provider
      value={{
        setFlow,
        addFlow,
        addNextFlowPage,
        removeNextFlowPage,
        removeFlow,
        clearFlow: clearState,
        getIndex,
        getNumSteps,
        goToNextPage,
        goToPrevPage,
        goToHomePage,
        appendNextPage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigationContext = (key?: string) => {
  const context = useContext(NavigationContext);

  useEffect(() => {
    if (key) {
      context.setFlow(key);
    }
  }, [key]);

  return context;
};

export { NavigationContext, NavigationProvider, useNavigationContext };
