'use client';

import { datadogRum } from '@datadog/browser-rum';
import { AppointmentType } from 'database';
import { useSession } from 'next-auth/react';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';
import { trpc } from 'trpc/client';

type ContextProps = {
  user?: any;
  numAppointments?: number;
  numScreens?: number;
  utmParams?: any;
  features?: Record<string, any>;
  setUtmParams?: any;
  appointmentTypes?: (AppointmentType | undefined)[];
  isZendeskWidgetOpen?: boolean;
  setIsZendeskWidgetOpen?: Dispatch<SetStateAction<boolean>>;
};

const AppContext = createContext<ContextProps>({});

type Props = {
  children: ReactNode;
};

const AppProvider: FC<Props> = ({ children }) => {
  const { status } = useSession();
  const [utmParams, setUtmParams] = useState<any>({});
  const [isZendeskWidgetOpen, setIsZendeskWidgetOpen] = useState<boolean>(false);
  const { data } = trpc.user.get.useQuery(undefined, {
    enabled: status === 'authenticated',
  });
  const {
    data: { features },
  } = trpc.features.list.useQuery(undefined, { initialData: { features: {} } });
  const { data: appointmentTypes } = trpc.appointments.getTypes.useQuery(undefined, {
    enabled: status === 'authenticated',
    initialData: [],
  });

  useEffect(() => {
    datadogRum.setUser({
      id: data?.user?.id,
    });
  }, [data?.user?.id]);

  return (
    <AppContext.Provider
      value={{
        user: data?.user,
        utmParams,
        features,
        setUtmParams,
        appointmentTypes,
        isZendeskWidgetOpen,
        setIsZendeskWidgetOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
