'use client';

import { ZENDESK_CONVERSATION_FIELDS } from 'shared/lib/constants';

export const zdCustomFields = () => {
  const conversationFields = Object.values(ZENDESK_CONVERSATION_FIELDS).map((id) => ({
    id,
    value: document.getElementById(`${id}`)?.innerHTML,
  }));

  zdSet(['conversationFields', conversationFields]);
};

export const zdSubscribe = async (openCb: Function, closedCb: Function) => {
  const { zE } = window;

  if (zE) {
    await zE('messenger:on', 'open', openCb);
    await zE('messenger:on', 'close', closedCb);
  }
};

export const zdHide = async () => {
  await zdAction('hide');
};

export const zdShow = async () => {
  await zdAction('show');
};

export const zdClose = async () => {
  await zdAction('close');
};

export const zdOpen = async () => {
  await zdAction('open');
};

export const zdLocale = (locale: string) => {
  zdSet(['locale', locale]);
};

export const zdSet = (opts: any[]) => {
  const { zE } = window;

  zdClose();
  if (zE) {
    zE('messenger:set', 'zIndex', 999999);
    zE('messenger:set', ...opts);
  }
};

export const zdAction = async (action: string) => {
  const { zE } = window;

  if (zE) {
    await zE('messenger', action);
  }
};

export const zdRemove = async () => {
  // My hope was by fully removing, then re-adding the script, that it would
  // reset and re `load` it after language change.  But it does not reload.
  // This code will fully remove the Zendesk pieces, afaict.  But there may
  // be more.  Leaving here in case we want to explore this more.  This gets
  // us part way there.
  await zdClose();
  await zdHide();
  const iframes = document.getElementsByTagName('iframe');
  const frameArr = Array.from(iframes);

  const button = document.getElementById('launcher');
  button?.remove();

  for (const frame of frameArr) {
    if (
      Array.from(frame.attributes).find(
        (att: any) => att.name === 'data-product' && att.nodeValue === 'web_widget',
      )
    ) {
      frame.remove();
    }
  }
  for (const frame of frameArr) {
    if (frame.title === 'Messaging window') {
      frame.remove();
    }
  }
  document.getElementById('ze-snippet')?.remove();
};

export const zdAdd = async (onOpen: Function, onClose: Function, locale: string) => {
  if (!window.document?.getElementById('ze-snippet')) {
    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=8933b6f0-1252-436c-b708-aa8062a92d03';
    script.onload = async () => {
      zdCustomFields();
      zdSubscribe(onOpen, onClose);
      zdLocale(locale);
      zdOpen();
    };

    document.body.appendChild(script);
  }
};
